.banner {
    height: 10em;
    position: relative;
    width: 100%;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 80% 40%;
}

.title {
    color: var(--off-white);
    font-weight: lighter;
    font-size: 56px;
    margin-top: .1em;
    margin-left: .8em;
    text-align: left;
}

.bannerContent {
    top: 1em;
    left: 0;
    background-color: transparent;
    position: absolute;
    width: 100%;
}

.container {
    display: flex;
    padding-bottom:1em;
    margin: 4em;
    background: var(--white);
    border: 2px solid var(--light_grey);
    justify-content: center;

}

.header {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 36px;
    font-weight: bolder;
    color: var(--dark-grey);
}

.subHeader {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 21px;
    font-weight: bolder;
    color: var(--dark-grey);
    padding-bottom: 0;
    margin-bottom: 0;
}