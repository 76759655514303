.navBarContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: auto;
  align-self: flex-end;
}

.navBarStyle {
   /*color: #365b4c;*/
   color: #cbc3c3;
   margin-left: 25px;
}

:link {
    text-decoration: none;
    color: var(--dark-grey);
    cursor: pointer;
    transition: border .5s;
    font-weight: bolder;
    font-size: 26px;
}

:visited {
    text-decoration: none;
    color: var(--dark-grey);
    font-weight: bolder;
    font-size: 28px;
}







