.banner {
    height: 40em;
    position: relative;
    width: 100%;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 20% 60%;
}

.bannerContent {
    top: 0;
    left: 0;
    background-color: transparent;
    position: absolute;
    width: 100%;
}

.dataLayer {
    margin-top: 1em;
    margin-right: 1em;
    text-align: right;
}

.dataLayerDropdown {
    padding: 5px 14px;
    font-size: 18px;
    background: #e8e8e8;
    border: 2px solid var(--dark-grey);
    color: #1e1e1e;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    outline: none;
}

.dataLayerButton {
    padding: 5px 14px;
    font-size: 18px;
    background: #e8e8e8;
    border: 2px solid var(--dark-grey);
    color: #1e1e1e;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    margin: 4px 5px;
    cursor: pointer;

}

.cartLink {
    color: var(--off-white);
    font-weight: lighter;
    font-size: 20px;
    margin-top: 1.5em;
    margin-right: 2em;
    text-align: right;
}

.title {
    color: var(--off-white);
    font-weight: lighter;
    font-size: 56px;
    margin-top: .1em;
    margin-left: .8em;
    text-align: left;
}

.navBar {
    margin-top: 18em;
    margin-left: .8em;
    padding: 5px;
}

.navBarBanner {
    color: var(--off-white);
    font-weight: lighter;
    font-size: 56px;
}

