hr {
    color: var(--light_grey);
}

.outerProductContainer {
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: column;
    max-width: 50%;
}

.productContainerLeft {
    flex: 1 0 70%;
    padding: 1em;
    margin-left: 20%;
    margin-right: 30%;
    position: relative;
    cursor: pointer;
}

.productContainerRight {
    padding: 1em;
    width: 20%;
    margin-right: 3em;
    margin-left: 70%;
    margin-top: 2em;
    bottom: 10%;
    position: fixed;
    cursor: none;
    background: var(--white);
    border: 2px solid var(--light_grey);
    align-content: start;
}

.productAmount {
    white-space: nowrap;
    display: flex;
    height: 5em;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
}

.productAmountDetail {
    /*display: inline-block;*/
    margin: .2em;
    /*justify-content: center;*/
            display: flex;
}

.detailText {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 18px;
    font-weight: normal;
    color: var(--dark-grey);
}

.detailHeaderText {
    text-align: left;
    flex-wrap: nowrap;
    font-family: var(--font);
    font-size: 28px;
    font-weight: bold;
    margin-bottom: .1em;
    display: block;
    width: max-content;
    color: var(--dark-grey);
}

.circle {
    border-radius: 100%;
    background: var(--dark-grey);
    color: #fff;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: .8rem;
    display: flex;
    justify-content: center;
}

.productAmountButtonLeft {
    padding: 0;
    border: none;
    background: none;
    font-size: 24px;
    font-weight: lighter;
    margin-left: 20px;
    color: var(--dark-grey);
}

.productAmountButtonRight {
    padding: 0;
    border: none;
    background: none;
    font-size: 24px;
    font-weight: lighter;
    color: var(--dark-grey);
}

.cartSum {
    text-align: left;
    color: var(--dark-grey);
}

.orderButton {
    text-align: center;
}

.orderButtonContent {
    padding: 5px 36px;
    border: none;
    font-size: 18px;
    background: var(--dark-grey);
    color: var(--white);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}

.orderButtonContent:hover {
    background-color: var(--brown);
    color: var(--white);
}

.imageSize {
    border: 1px solid var(--light_grey);
    padding: 20px;
    width: 500px;
    height: 400px;
    margin: auto;
}

.quantity {
    color: var(--dark-grey);
    font-size: 19px;
}

.emtpyCart {
    padding: 5px 36px;
    font-size: 19px;
    background: none;
    color: var(--dark-grey);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    margin: auto;
    cursor: pointer;
    border: 2px solid var(--light_grey);
}

.emtpyCart:hover {
    background-color: var(--brown);
    color: var(--white);
}
