.flexContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.flexContainer > div {
  margin: 10px;
  padding: 20px;
}

.imageDisplay {
    /*border: 1px solid var(--light_grey);*/
    margin: auto;
    display: inline-flex;
    width: 60%;
    height: 90%;
    object-fit: cover;
}

.imageSize {
    float: left;
    width:  100%;
    height: 100%;
    object-fit: cover;
}

.textDisplay {
    margin: auto;
    width: 40%;
    height: 90%;
}

.detailHeaderText {
    text-align: left;
    flex-wrap: nowrap;
    font-family: var(--font);
    font-size: 36px;
    font-weight: bold;
    color: var(--dark-grey);
}

.detailTextDescription {
    text-align: left;
    flex-wrap: wrap;
    font-size: 16px;
    font-weight: lighter;
    max-width: 60%;
    color: var(--dark-grey);
}

.detailText {
    text-align: left;
    flex-wrap: nowrap;
    font-family: var(--font);
    font-size: 24px;
    font-weight: lighter;
    color: var(--dark-grey);
}

.addToCartButton {
    text-align: left;
}

.addToCartButtonContent {
    padding: 5px 14px;
    font-size: 18px;
    background: none;
    border: 2px solid var(--dark-grey);
    color: var(--dark-grey);
    white-space: nowrap;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}

.addToCartButtonContent:hover{
  background-color: var(--dark-grey);
  color: #fff;
}