.picture{
    display: flex;
    max-width: 100%;
    height: auto;
    margin: auto;
    object-fit: contain;
}

.headerText {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 36px;
    font-weight: bolder;
    margin: 1em;
    color: var(--dark-grey);
}

.introText {
    text-align: center;
    flex-wrap: wrap;
    font-size: 23px;
    font-weight: lighter;
    margin: 3em;
    color: var(--dark-grey);
}
