.banner {
    height: 10em;
    position: relative;
    width: 100%;
}

.image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 80% 40%;
}

.title {
    color: var(--off-white);
    font-weight: lighter;
    font-size: 56px;
    margin-top: .1em;
    margin-left: .8em;
    text-align: left;
}

.bannerContent {
    top: 1em;
    left: 0;
    background-color: transparent;
    position: absolute;
    width: 100%;
}


.header {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 36px;
    font-weight: bolder;
    color: var(--dark-grey);
}

.subHeader {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 21px;
    font-weight: bolder;
    color: var(--dark-grey);
    padding-bottom: 0;
    margin-bottom: 0;
}

.container {
    display: flex;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;

}

.productContainerRight {
    padding: 1em;
    width: 20%;
    margin-right: 3em;
    margin-left: 70%;
    margin-top: 2em;
    bottom: 10%;
    position: fixed;
    cursor: none;
    background: var(--white);
    border: 2px solid var(--light_grey);
    align-content: start;
}

.productContainerLeft {
    flex: 1 0 70%;
    padding: 1em;
    margin-left: 20%;
    margin-right: 30%;
    position: relative;
    cursor: pointer;
}

.cartSum {
    text-align: left;
    color: var(--dark-grey);
}

.orderButton {
    text-align: center;
}

.orderButtonContent {
    padding: 5px 36px;
    border: none;
    font-size: 18px;
    background: var(--dark-grey);
    color: var(--white);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}

.orderButtonContent:hover {
    background-color: var(--brown);
    color: var(--white);
}

.productContainerLeft {
    margin: 5em;
}

.dropdown {
    padding: 5px 14px;
    font-size: 18px;
    background: #e8e8e8;
    border: 2px solid var(--dark-grey);
    color: #1e1e1e;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 4px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    outline: none;
    width: 15em;
}