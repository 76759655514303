.grid{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1em;
    margin: auto;
}

.headerText {
    text-align: left;
    flex-wrap: nowrap;
    font-size: 36px;
    font-weight: bolder;
    margin: 1em;
    color: var(--dark-grey);
}