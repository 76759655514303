:root {
  --light_grey: #D4D4D4;
  /*--gradient: linear-gradient(-225deg, rgba(72,130,169,0.6) 0%, rgba(0,36,61,0.6) 50%);*/
  --font: Arial, "Lucida Grande", sans-serif;
  --dark-grey: #312c18;
  --white: #ffff;
  --off-white: #cbc3c3;
  --brown: #3f3a2b;
}

:link {
    text-decoration: none;
    color: var(--dark-grey);
    cursor: pointer;
    transition: border .5s;
}

:visited {
    text-decoration: none;
    color: var(--dark-grey);
}

/*:hover {*/
/*    !*text-decoration: underline;*!*/
/*    color: #858484;*/
/*    cursor: pointer;*/
/*}*/

body {
  margin: 0;
}
