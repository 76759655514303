.tile {
    padding: 10px;
    margin: auto;
}

.imageGrid {
    border: 1px solid var(--light_grey);
    padding: 20px;
    width: 400px;
    height: 300px;
    margin: auto;
    /*box-sizing: border-box;*/
}

.addToCartButton {
    text-align: left;
}

.addToCartButtonContent {
    padding: 5px 14px;
    font-size: 18px;
    background: none;
    border: 2px solid var(--dark-grey);
    color: #1e1e1e;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 2px;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}

.addToCartButtonContent:hover {
    background-color: var(--dark-grey);
    color: #fff;
}

.titleLink {
    text-decoration: none;
    color: var(--dark-grey);
    cursor: pointer;
    transition: border .5s;
    font-size: 22px;
    font-family: var(--font);
}
